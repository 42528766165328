export default defineNuxtRouteMiddleware((to, from) => {
  const localePath = useLocalePath()
  if (to.path === '/showcase' || to.path === '/it/showcase') {
    //const { path, query, hash } = to
    //const nextPath = path.replace(/\/+$/, '') || '/'
    const nextRoute = localePath({
      name: 'projects'
    })
  
    return navigateTo(nextRoute)
  }
})
